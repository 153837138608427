import './style.scss'
import Title from '@/components/Title'
import Envelope from "./img/envelope.png";
import Heart from "./img/heart.png";
import Join from "./img/join.png";
export default function Footer() {
    return (
        <>
        <div className='container my-5 footer'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Title title='Want to Get in Touch?' />
                    </div>
                    <div className="col-md-4">
                        <a target="_BLANK" rel="noreferrer" href="mailto:adxwa@theworldgolfleague.com">
                            <div className="footer-card">
                                <h3>Email Us!</h3>
                                <img className="footer-icon" alt="email" src={Envelope}/>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a target="_BLANK" rel="noreferrer" href="https://www.paypal.me/TheWGL">
                            <div className="footer-card">
                                <h3>Donate!</h3>
                                <img className="footer-icon" alt="donate" src={Heart}/>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a target="_BLANK" rel="noreferrer" href="https://discord.gg/xWVcHJbc9K">
                            <div className="footer-card">
                                <h3>Join Us!</h3>
                                <img className="footer-icon" alt="join us" src={Join}/>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright">
                <p>Copyright © World Golf League {new Date().getFullYear()}. Developed by <a target="_BLANK" rel="noreferrer" href="https://tavansystems.com">Tavan Systems Inc.</a></p>
            </div>
            </>
    )
}