import { useEffect, useState, useContext } from 'react'

import SpreadsheetContext from '@/utils/SpreadsheetContext'

import './style.scss'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function HeadlineCards() {
    const [didFetch, setDidFetch] = useState(false)
    const [headlines, setHeadLines] = useState(null)
    const doc = useContext(SpreadsheetContext)['wgl-elite-b']
    
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    };
    const fetchData = () => {
      (async function () {
        console.log(doc.loadInfo())
        await doc.loadInfo();
        const resultsSheet = doc.sheetsByTitle['News Feed'];
        const resultsRows = await resultsSheet.getRows();
        setDidFetch(true)
        if (!resultsRows.length) return
        const data = resultsRows.map((row, index) => {
            return {
                position: index + 1,
                date: row['Date'],
                title: row['Title'],
                excerpt: row['Excerpt'],
                pictureurl: row['Picture URL'],
            };
        }).filter((r) => {
            return r.title;
        });
        setHeadLines(data)
      }())

    }
  
    useEffect(() => {
      if (didFetch === false) {
        fetchData()
      }
    })


    if (!headlines) return <></>
    return (
        <Carousel autoPlay={true} arrows={false} responsive={responsive} className="headlines-carousel">
            {headlines.map(({ date, title, excerpt, pictureurl }) => (
                <div key={title}>
                    <div className="card">
                        <img alt={title} src={pictureurl} className="img-fluid" />
                        <div className="text">
                            <center>
                                <h3>{title}</h3>
                                <p><b>{date}</b></p>
                                <p>{excerpt}</p>
                            </center>
                        </div>
                    </div>
                </div>
            ))}
        </Carousel>
    )
}