import Main from '@/templates/Main'
import Title from '@/components/Title'
import Card from '@/components/Card'

export default function LeagueStructure() {
    return (
        <Main>
            <div className='row'>
                <div className='col-md-12'>
                    <Title title="League Structure" />
                    <Card>
                        <h2>The WGL is currently compromised of 4 societies:</h2>
                        <h3>WGL – Q-School</h3>
                        <p>This is the place where new members go to qualify for our main tours. There are 4 events over the span of two weeks in each season. Each event will consist of 2 rounds spread over a few days (Saturday to Monday & Tuesday to Friday).</p>
                        <p>New members are encouraged to play in all tournaments in order to obtain points. The better you position in the standings, the more points you get. </p>
                        <p>A win in ANY Q-School event is Automatic promotion to our Tours. The Top 3 after a Q-School Season (less the automatically promoted players) will also be promoted. If you play 3 out of 4 events in 2 consecutive seasons but are unable to crack the top 3, you will be automatically promoted to the Elite B Tours.</p>
                        <p>Stay committed and you WILL qualify!</p>

                        <h3>WGL Elite Tours</h3>
                        <p>WGL Elite A Tours (<b>WGL Elt-A – PGA Tour & WGL Elt-A – Euro Tour</b>)</p>
                        <p>The 36 best players in the WGL compete in the Elite A Tours.</p>
                        <p>Tours (<b>WGL Elt-B – PGA Tour & WGL Elt-B – Euro Tour</b>)</p>
                        <p>Everyone else competes in the Elite B Tours.</p>
                        <b>Playoffs:</b>
                        <ul>
                            <li>At the Elite A level, the Top 25 compete in the WGL A-Tour Championship at the end of the Season. Top 16 after that will play a bracket matchplay tournament to crown our Elite A Champion.</li>
                            <li>At the Elite B level, the Top 32 compete in the WGL B-Tour Championship at the end of the Season. Top 16 after that will play a bracket matchplay tournament to crown our Elite B Champion.</li>
                        </ul>
                        <b>Promotion and Relegation:</b>
                        <p>At the end of the Season, the Bottom 11 Players in Elite A will be relegated down to Elite B for the following season, while the Top 11 Players in Elite B will be given a promotion to Elite A. </p>

                        <h3>WGL – 19th Hole</h3>
                        <p>This is where everyone comes to play.</p>
                        <p>Run by the mad scientist himself (SUPER DOOPCFACE), the 19th Hole is full of different events with different settings and many surprises.Maybe you’ll face hurricane force winds. Maybe you’ll play from the tips one round and the backs the next. Maybe it’ll be a high rollers event! It’s a mixed bag of goodies and you don’t want to miss out!</p>
                    </Card>
                </div>
            </div>
        </Main>
    )
}