import { useEffect, useState, useContext } from 'react'

import SpreadsheetContext from '@/utils/SpreadsheetContext'

import Main from '@/templates/Main'
import Table from '@/components/Table'
import Title from '@/components/Title'

export default function EventResults({ match }) {
  const [didFetch, setDidFetch] = useState(false)
  const [results, setResults] = useState([])
  const { wglTour, sheet } = match.params
  const doc = useContext(SpreadsheetContext)[wglTour]

  const fetchData = () => {
    (async function () {
      await doc.loadInfo();
      const resultsSheet = doc.sheetsByTitle[sheet];
      const resultsRows = await resultsSheet.getRows();
      setDidFetch(true)
      if (!resultsRows.length) return
      const data = resultsRows.map((row, index) => {
        return {
          pos: row['Pos'],
          player: row['Player Name'],
          nationality: <img alt={`${row['Nationality']} Flag`} className="country-icon" src={`https://www.sciencekids.co.nz/images/pictures/flags96/${row['Nationality']}.jpg`} />,
          round_one: row['R1'],
          round_two: row['R2'],
          round_three: row['R3'],
          round_four: row['R4'],
          total: row['Total'],
          score: row['Score'],
          points: row['Pts'],
          eventname: row['EVENT NAME'],
        }
      }).filter((r) => {
        return r.player;
      });
      setResults(data)
    }())
  }

  useEffect(() => {
    if (didFetch === false) {
      fetchData()
    }
  })

  return (
    <Main>
      <div className="row">
        <div className="col-md-12">
          <Title title={(results[0] ? results[0].eventname : <div style={{ textAlign: "center" }}><i style={{ animation: "spin 2s linear infinite", fontSize: "45px", color: "white", margin: "0 auto", alignSelf: "center" }} className="fa fa-spinner" /></div>)} />
        </div>
        <div className="col-md-12">
          <div className="table-responsive">
            <Table
              data={results}
              isLoading={!didFetch}
              columnHeaders={['#', '', 'Player', '+/-', 'R1', 'R2', 'R3', 'R4', 'Total', 'Points']}
              columnKeys={['pos', 'nationality', 'player', 'score', 'round_one', 'round_two', 'round_three', 'round_four', 'total', 'points']}
            />
          </div>
        </div>
      </div>
    </Main>
  )
}