import './style.scss'
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function Table({data, additionalHeader, columnKeys, columnHeaders, colSpan, foot, isLoading}) {
  const [tableSortKey, setTableSortKey] = useState()
  const [tableSortOrder, setTableSortOrder] = useState()
  const [initialCount] = useState()
  
  // const viewMoreResults = () => {
  //   // setInitialCount(initialCount + 10 >= data.length ? data.length : initialCount + 10)
  //   setInitialCount(data.length)
  // }

  return (
    <div className="table-responsive table-parent">
      <table className="table">
        <thead>
          {additionalHeader}
          <tr>
            {columnHeaders.map(
              (header, index) => {
                const key = columnKeys[index];
                return (
                  <th className="pointer" key={index} onClick={() => {
                    setTableSortKey(key)
                    setTableSortOrder((tableSortKey === key ? -tableSortOrder : 1))
                  }}>
                    {header}
                    {(tableSortOrder === -1 &&
                      tableSortKey === key
                      ?
                      <i className="fa fa-sort-amount-asc"></i>
                      :
                      <i className="fa fa-sort-amount-desc"></i>)}
                  </th>
                )
              })}
          </tr>
        </thead>
        <tbody>
          {
            data.slice(0, initialCount).sort(
              (a, b) => {
                const x = a[tableSortKey];
                const y = b[tableSortKey];
                if (x < y) {
                  return 1 * tableSortOrder;
                } else {
                  return -1 * tableSortOrder;
                }
              }
            ).map((row, index) => {
              return (
                <tr key={index}>
                  {
                    columnKeys.map(
                      (key, index) => {
                        return (
                          <td
                            key={index}
                          >
                            {(Number.isInteger(row[key])
                              ? <NumberFormat
                                value={row[key]}
                                displayType={'text'} thousandSeparator={true}
                              />
                              : (row[key] ? row[key] : "null"))}
                          </td>
                        )
                      }
                    )
                  }
                </tr>
              )
            }
            )}
          {
            (data.length === 0 && !isLoading ?
              <tr><td align="center" colSpan={columnHeaders.length}>No data</td></tr>
              :
              null
            )
          }
          {
            (isLoading ?
              <tr><td align="center" colSpan={columnHeaders.length}><i style={{animation: "spin 2s linear infinite", color: "white", margin:"0 auto", alignSelf: "center"}} className="fa fa-spinner" /></td></tr>
              :
              null
            )
          }
        </tbody>
        {foot}
      </table>
      {/* <button disabled={initialCount >= data.length} onClick={viewMoreResults} className="view-more">View More</button> */}
    </div>
  );
}

Table.propTypes = {
  additionalHeader: PropTypes.func,
  columnHeaders: PropTypes.array.isRequired,
  columnKeys: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  foot: PropTypes.func
}

export default Table;