import { GoogleSpreadsheet } from 'google-spreadsheet'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import SpreadsheetContext from './utils/SpreadsheetContext'

import Home from './views/Home'
import About from './views/About'
import LeagueStructure from './views/LeagueStructure'
import Rules from './views/Rules'
import PGATourB from './views/Tours/PGATour'
import EuroTourB from './views/Tours/EuroTour'
import StandingsB from './views/Tours/Standings'
import EventResults from './views/EventResults'
import NineteenthHole from './views/NineteenthHole'

function App() {
  const apiKey = "AIzaSyDf10NTdUCZsH3jsvID3Lwdu6wmbf7mWcY"
  const eliteAdoc = new GoogleSpreadsheet('1qwYRa3GxK7ZFcKQTL-dFbXWdm1gEEhsQFr71Fyis7aY')
  eliteAdoc.useApiKey(apiKey)
  const eliteBdoc = new GoogleSpreadsheet('1YORKoRlBQ2XJEb3LadHcTmS19bcA15SCe8ofjjkBNzo')
  eliteBdoc.useApiKey(apiKey)
  const nineteenthHoledoc = new GoogleSpreadsheet('1hnLNAn5p2MKvTQkjgaTQXE9rTwC9MHoLpEi_aqatI9c')
  nineteenthHoledoc.useApiKey(apiKey)

  return (
    <SpreadsheetContext.Provider value={{'wgl-elite-a': eliteAdoc, 'wgl-elite-b': eliteBdoc, 'nineteenth-hole': nineteenthHoledoc}}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about-us">
            <About />
          </Route>
          <Route exact path="/league-structure">
            <LeagueStructure />
          </Route>
          <Route exact path="/rules-and-conduct">
            <Rules />
          </Route>
          <Route exact path="/:wglTour/:tour/event/:sheet" component={EventResults} />
          <Route exact path="/tours/pga-tour">
            <PGATourB />
          </Route>
          <Route exact path="/tours/euro-tour">
            <EuroTourB />
          </Route>
          <Route exact path="/tours/standings">
            <StandingsB />
          </Route>
          <Route exact path="/19th-hole">
            <NineteenthHole />
          </Route>
        </Switch>
      </Router>
    </SpreadsheetContext.Provider>
  )
}

export default App;