import './styles.scss'

export default function Title({title, children}){
    return (
        <div className="title justify-content-center">
            <h2>
                {title || children}
            </h2>
        </div>     
    )
}