import './style.scss'
import Title from "@/components/Title";
import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
// import WorldWideGolfRankings from '@/components/WorldWideGolfRankings'

export default function Main({ children }) {
    return (
        <>
            <NavBar />
            <div className='container my-5'>
                <div className='row'>
                    <div className='col-md-9'>
                        {children}
                    </div>
                    <div className='col-md-3'>
                        {/* <WorldWideGolfRankings />
                        <br /> */}
                        <Title title='Our Twitter' />
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="worldgolfleague"
                            options={{ height: 400 }}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}