import Main from '@/templates/Main'
import Title from '@/components/Title'
import Card from '@/components/Card'

export default function Rules() {
    return (
        <Main>
            <div className='row'>
                <div className='col-md-12'>
                    <Title title="Rules and Member Conduct" />
                    <Card>
                        <ol>
                            <li>Absolutely NO USE of 3rd party controllers is allowed in the WGL. Any controller that has not been tested or approved by Xbox is not permitted during league play.
                            To enforce this rule, ALL PLAYERS must record 2 one-minute recordings of themselves per tournament in the WGL. This must be made available on your Xbox platform. No upload anywhere else is required. Only IF a moderator requests it will you need to have access to it. Consider this a way of covering your own rear.</li>
                            <li>Putter must be used on the green unless your putting line is obstructed by fringe/rough/bunker or your putt is longer than 75 feet, in which case use of a putter is not mandatory. 1st violation will result in a disqualification from that event. 2nd violation will result in a season long ban. 3rd violation will result in a 3 Season capital ban from the WGL. We have a host of players who ghost rounds at random. You will get caught if you do it.</li>
                            <li>No malicious, derogatory, degrading, racist, sexist, homophobic, offensive, harassing, or otherwise hostile comments will be tolerated by the WGL. Failure to follow this rule will be punished by immediate temporary suspension of the member. The management team will meet and discuss the gravity of the member’s conduct, and a sentence will be passed down. A serious or aggravated first-time offense may result in an immediate, permanent ban, without notice to the offender. Please report all issues of this manner to <a href="mailto:adxwa@theworldgolfleague.com">adxwa@theworldgolfleague.com</a></li>
                            <li>The World Golf League strives to preserve and protect the centuries-old notion of golf as a game where sportsmanship and self-respect are the pillars upon which all the rules of the game are built. The WGL expects all Staff and members to carry themselves in a sportsmanlike and courteous manner. Interactions will take place on the course, in the forums, when reviewing courses, and elsewhere. Any issue that arises should be directed to a staff member, at which point it will be discussed with AdxwA and the management team.</li>
                            <li>Should you break any of the above rules, the WGL reserves the right to revoke your membership and issue a ban from the league. Your participation in our league play infers that you have read our league rules and agree to abide by them.</li>
                            <li>Finally, have fun! This is the greatest and most competitive league in 2K golf right now, and we hope to continue our upwards trajectory. The WGL is a family and we hope you enjoy bringing your golf game to the next level, and competing for glory.</li>
                        </ol>
                    </Card>
                </div>
            </div>
        </Main>
    )
}