import "./style.scss";
import { Link } from "react-router-dom";
import GolfBall from "./img/golf-ball.png";
import Logo from "./img/logo.png";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

export default function NavBar() {
  return (
    <Navbar collapseOnSelect expand="lg">
      <div className="container">
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <i className='fa fa-bars'></i>
        </Navbar.Toggle>
        <Navbar.Brand as={Link} to="/">
          <img alt="World Golf League Logo" className='branding' src={Logo} />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-center" id="responsive-navbar-nav">
          <Nav>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <NavDropdown title="Tours" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/tours/pga-tour">PGA Tour</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tours/euro-tour">European Tour</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tours/standings">Play-Off Standings</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/19th-hole">19th Hole</Nav.Link>
            <Nav.Link as={Link} to="/about-us">About Us</Nav.Link>
            <Nav.Link as={Link} to="/rules-and-conduct">Rules</Nav.Link>
            <Nav.Link as={Link} to="/league-structure">League Structure</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Nav>
          <img className="navbar-golfball" alt="Golf Ball" src={GolfBall}/>
        </Nav>
      </div>
    </Navbar>
  )
}
