import { useEffect, useState, useContext } from 'react'

import SpreadsheetContext from '@/utils/SpreadsheetContext'

import {Link} from 'react-router-dom'
import Main from '@/templates/Main'
import Table from '@/components/Table'
import Title from '@/components/Title'

export default function PGATour() {
    const [didFetch, setDidFetch] = useState(false)
    const [leaderBoards, setLeaderBoards] = useState([])
    const [schedule, setSchedule] = useState([])
    const doc = useContext(SpreadsheetContext)['wgl-elite-b']

    const fetchData = () => {
        (async function () {
            await doc.loadInfo();
            const scheduleSheet = doc.sheetsByTitle['EventSchedule'];
            const scheduleRows = await scheduleSheet.getRows();
            setDidFetch(true)
            if (!scheduleRows.length) return
            const schedule = scheduleRows.map((row, index) => {
                return {
                    position: index + 1,
                    event: row['Event'],
                    event_name: row['Event Name'],
                    course: row['Course'],
                    tour: row['Tour'],
                    sheet: row['Sheet'],
                    view: <Link to={`/wgl-elite-b/pga-tour/event/${row['Sheet']}`}><button className={"table-btn"} disabled={!row['Sheet']}>Results</button></Link>,
                    start_date: row['StartDate'],
                    end_date: row['EndDate'],
                };
            }).filter((r) => {
                return r.tour === "PGA" && r.course;
            });
            setSchedule(schedule)

            const leaderboardSheet = doc.sheetsByTitle['FEDEXClean'];
            const leaderboardRows = await leaderboardSheet.getRows();
            if (!leaderboardRows.length) return
            const leaderboard = leaderboardRows.map((row, index) => {
                return {
                    position: row['Pos'],
                    player: row['Player'],
                    nationality: <img alt={row['Nationality'] + " Flag"} className="country-icon" src={`https://www.sciencekids.co.nz/images/pictures/flags96/${row['Nationality']}.jpg`} />,
                    points: row['FEDEX Points'],
                    wins: row['Wins'],
                    top_ten: row['Top 10s'],
                };
            }).filter((r) => {
                return r.player;
            });
            setLeaderBoards(leaderboard);
        }())
    }

    useEffect(() => {
        if (didFetch === false) {
            fetchData()
        }
    })

    return (
        <Main>
            <div className="row">
                <div className="col-md-12">
                    <Title title="PGA Tour" />
                </div>
                <div className="col-md-12">
                    <div className="table-responsive">
                        <div className="table-header">
                            <h2>Schedule</h2>
                        </div>
                        <Table
                            data={schedule}
                            isLoading={!didFetch}
                            columnHeaders={['event', 'event name', 'course', 'start date', 'end date', '']}
                            columnKeys={['event', 'event_name', 'course', 'start_date', 'end_date', 'view']}
                        />
                    </div>
                    <br />
                    <div className="table-responsive">
                        <div className="table-header">
                            <h2>Fedex Leaderboard</h2>
                        </div>
                        <Table
                            data={leaderBoards}
                            isLoading={!didFetch}
                            columnHeaders={['Pos.', 'player', '', 'points', 'wins', 'top ten']}
                            columnKeys={['position', 'player', 'nationality', 'points', 'wins', 'top_ten']}
                        />
                    </div>
                </div>
            </div>
        </Main>
    )
}