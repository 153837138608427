import { TwitchEmbed } from 'react-twitch-embed';
import "./style.scss";
import Golfer from "./img/golfer.png";
import GolfHand from "./img/golf-hand.png";
import BallHand from "./img/hand-holding-ball.png";

import Title from "@/components/Title";
import HeadlineCards from '@/components/HeadlineCards'
// import TourneySlider from '@/components/TourneySlider'
import Testimonials from '@/components/Testimonials'
import Footer from '@/components/Footer'
// import WorldWideGolfRankings from '@/components/WorldWideGolfRankings'
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import NavBar from '../../components/NavBar';

export default function Home() {
    return (
        <>
            <NavBar />
            <div className='container-fluid'>
                <div className='row'>
                    <div
                        className='col-md-6 welcome-hero'
                        style={{ backgroundImage: `url(${Golfer})` }}></div>
                    <div className='col-md-6 align-self-center'>
                        <div className='welcome-message'>
                            <h1 className='d-flex justify-content-center'>
                            Season 5 Is Live! Aug. 7th - Oct. 16th
              </h1>
                            <p className="quote">
                                "Confidence is the most important factor in this game, and no
                                matter how great your natural talent, there is only one way to
                                obtain and sustain it: work." - Jack Nicklaus
              </p>
                            <div className='button-group'>
                                <a
                                    className='btn'
                                    href='https://discord.gg/xWVcHJbc9K'
                                    target='_BLANK'
                                    rel='noreferrer'>
                                    Join Our Discord!
                                </a>
                                <a
                                    className='btn'
                                    href='https://www.paypal.me/TheWGL'
                                    target='_BLANK'
                                    rel='noreferrer'>
                                    Want to Help? Donate!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='container my-5'>
                <div className='row'>
                    <div className='col-md-4'>
                        <WorldWideGolfRankings />
                    </div>
                    <div className='col-md-12'>
                        <Title title='Tourney Winners' />
                        <TourneySlider />
                    </div>
                </div>
            </div> */}
            <div className='container my-5'>
                <div className='row'>
                    <div className='col-md-9'>
                        <Title title='Recent WGL Headlines' />
                        <HeadlineCards />
                    </div>
                    <div className='col-md-3'>
                        <Title title='Our Twitter' />
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="worldgolfleague"
                            options={{ height: 400 }}
                        />
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className="col-md-12">
                        <Title title='Join Us Live on Twitch!' />
                    </div>
                </div>
            </div>
            <div className='container mr-0 mb-5'>
                <div className="row twitch-stream align-items-end">
                    <div className='col-md-8 my-3'>
                        <TwitchEmbed
                            channel="worldgolfleague"
                            className="stream"
                            theme="dark"
                            muted
                            onVideoPause={() => console.log(':(')}
                        />
                    </div>
                    <div className='col-md-4 p-0 d-none d-md-block'>
                        <img alt="Hand On Golf Club" className="img-fluid golf-hand" src={GolfHand} />
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className="col-md-12">
                        <Title title='Come Join Our Community!' />
                    </div>
                </div>
            </div>
            <div className='container ml-0 mb-5'>
                <div className="row discord-invite align-items-end">
                    <div className='col-md-4 my-3 pl-0 mb-0 d-none d-md-block'>
                        <img alt="Hand holding golf ball" className="img-fluid golf-hand p-0" src={BallHand} />
                    </div>
                    <div className='col-md-8 my-3'>
                        <iframe title="Discord Invite" src="https://discord.com/widget?id=697229119858933890&theme=dark" frameBorder={0} sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts" />
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Title title='Hear From Some Of Our Members' />
                        <Testimonials />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
