import { useEffect, useState, useContext } from 'react'

import SpreadsheetContext from '@/utils/SpreadsheetContext'

import Main from '@/templates/Main'
import Table from '@/components/Table'
import Title from '@/components/Title'

export default function PGATour() {
    const [didFetch, setDidFetch] = useState(false)
    const [schedule, setSchedule] = useState([])
    const doc = useContext(SpreadsheetContext)['nineteenth-hole']

    const fetchData = () => {
        (async function () {
            await doc.loadInfo();
            const resultsSheet = doc.sheetsByTitle['Events'];
            const resultsRows = await resultsSheet.getRows();
            setDidFetch(true)
            if (!resultsRows.length) return
            const data = resultsRows.map((row, index) => {
                return {
                    position: index + 1,
                    event: row['EventIndex'],
                    event_name: row['Event'],
                    course: row['Course']
                }
            }).filter((r) => {
                return r.event_name;
            });
            setSchedule(data)
        }())
    }

    useEffect(() => {
        if (didFetch === false) {
            fetchData()
        }
    })

    return (
        <Main>
            <div className="row">
                <div className="col-md-12">
                    <Title title="19th Hole" />
                </div>
                <div className="col-md-12">
                    <div className="table-responsive">
                        <div className="table-header">
                            <h2>Schedule</h2>
                        </div>
                        <Table
                            data={schedule}
                            isLoading={!didFetch}
                            columnHeaders={['event', 'event name', 'course']}
                            columnKeys={['event', 'event_name', 'course']}
                        />
                    </div>
                </div>
            </div>
        </Main>
    )
}