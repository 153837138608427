import { useEffect, useState, useContext } from 'react'

import SpreadsheetContext from '@/utils/SpreadsheetContext'

import Main from '@/templates/Main'
import Table from '@/components/Table'
import Title from '@/components/Title'

export default function Standings() {
  const [didFetch, setDidFetch] = useState(false)
  const [standings, setStandings] = useState([])
  const doc = useContext(SpreadsheetContext)['wgl-elite-b']

  const fetchData = () => {
    (async function () {
      await doc.loadInfo();
      const standingsSheet = doc.sheetsByTitle['Playoff Race'];
      const standingsRows = await standingsSheet.getRows();
      setDidFetch(true)
      if (!standingsRows.length) return
      const standings = standingsRows.map((row, index) => {
        return {
          pos: row['Position'],
          player: row['Player Name'],
          nationality: <img alt="Country Flag" className="country-icon" src={`https://www.sciencekids.co.nz/images/pictures/flags96/${row['Nationality']}.jpg`} />,
          totalpoints: row['Total Points']
        };
      }).filter((r) => {
        return r.player;
      });
      setStandings(standings)
    }())
  }

  useEffect(() => {
    if (didFetch === false) {
      fetchData()
    }
  })

  return (
    <Main>
      <div className="row">
        <div className="col-md-12">
          <Title title="Play Off Race" />
        </div>
        <div className="col-md-12">
          <div className="table-responsive">
            <Table
              data={standings}
              isLoading={!didFetch}
              columnHeaders={['#', '', 'Player', 'Total Points']}
              columnKeys={['pos', 'nationality', 'player', 'totalpoints']}
            />
          </div>
        </div>
      </div>
    </Main>
  )
}