import Main from '@/templates/Main'
import Title from '@/components/Title'
import Card from '@/components/Card'

export default function About() {
    return (
        <Main>
            <div className='row'>
                <div className='col-md-12'>
                    <Title title="Welcome to the WGL on Xbox!" />
                    <Card>
                        <h2>Admin Team</h2>
                        <div>
                            <p><strong>WGL Owner and President - AdxwA</strong></p>
                            <p><strong>WGL Tours Manager - AdxwA</strong></p>
                            <p><strong>Score Management - II BOMBER II</strong></p>
                            <p><strong>19th Hole Manager - Dallas Mavs82</strong></p>
                            <p><strong>Doubles League Manager - King Jonny1530</strong></p>
                            <p><strong>Course Testing Team - BANANAMAN96 & xxtml67xx</strong></p>
                            <p><strong>Official Course Designer List - GCS Insane</strong></p>
                            <p>Please note all staff are on Xbox until the PS4 Societies are eventually launched</p>
                        </div>
                        <h2>What is the WGL?</h2>
                        <p>We are a community of the world’s greatest golfers competing in a simulation style difficulty of the world’s greatest game. Currently on the PGA 2K21 platform and only on Xbox. Future expansion plans are underway to the PS4/PS5 market.</p>
                        <h2>How do I join?</h2>
                        <p><a href="https://discord.gg/xWVcHJbc9K" target="_BLANK" rel="noreferrer">Join our Discord!</a> Once inside, send our Society President “AdxwA” a private message confirming your gamertag and time zone. You will also be greeted with a Welcome message. Follow the instructions and you will be on your way!</p>
                        <h2>Can you tell me more about the League?</h2>
                        <p>Gladly. We pride ourselves on being the best golf league around, but we also know how to have fun. Once you have joined our Discord, you will be prompted to multiple leagues (please see our league structure tab for more info). One of these Leagues is the WGL – 19th Hole! In there you will be able to play on our settings against fellow outsiders and our own WGL pros! It’s a great way to see how you stack up against our players, before fully committing to the league!</p>
                    </Card>
                </div>
            </div>
        </Main>
    )
}