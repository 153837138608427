import './style.scss'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Testimonials() {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    };

    const testimonials = [
        {
            username: "ll BOMBER ll",
            age: 51,
            country: "United_States",
            message: "“I’ve been in this society for about 6 months. What a fantastic group! Great people, great camaraderie, and always someone challenging you and encouraging you to play your best!!”"
        },
        {
            username: "SportsmanHenry",
            age: 21,
            country: "United_States",
            message: "“The WGL has been a great way to meet guys all around the world who love playing PGA 2K! It’s always a great time playing a couple rounds with any of the guys, and it’s a challenging yet fun way to enjoy this game!”"
        },
        {
            username: "BUNKERDWELLER",
            age: 43,
            country: "United_Kingdom",
            message: "“Love WGL. Great community and a great bunch of guys running it, best society around.”"
        },
        {
            username: "WhoDaWhatNow",
            age: 25,
            country: "United_States",
            message: "“Living in a world now where social interaction has been so limited, it’s hard to undersell the value of good competition and camaraderie, like what is found within the WGL.”"
        },
        {
            username: "Sparkticus88",
            age: 32,
            country: "Scotland",
            message: "“WGL is a well structured society that puts the C in competitive. Their commitment to produce a professional experience to e-sports golf is 2nd to none“"
        },
    ]
    return (
        <>
            <Carousel infinite={true} autoPlay={true} arrows={false} responsive={responsive}>
                {testimonials.map(({ username, age, country, message }) => (
                    <div key={username} >
                        <div className="testimonial-item">
                            <p>{message}</p>
                            <div className="row person">
                                <div className="col-3">
                                    <img alt="Country Flag" className="avatar" src={`https://www.sciencekids.co.nz/images/pictures/flags96/${country}.jpg`} />
                                </div>
                                <div className="col-9">
                                    <div className="info">
                                        <b>{username}</b>
                                        <small><b>{age}</b></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
            <div className="row">
                <div className="col-md-12">
                    <div className="testimonial-item">
                        <h3>President’s Message:</h3>
                        <p>“The diversity of the WGL is unlike anything I’ve ever seen in the realm of sports gaming. Every category
of race, ethnicity, country of origin, and age is represented here. We pride ourselves on being a family
who love the greatest sport on earth. 
The WGL is a place for you to get your fix of fun and relaxing golf, as well as staging one of the most
competitive Pro level societies in the world of Golf gaming.
The future looks bright as we forage through this new territory in the world of e-sports, and we hope
you will join us for the ride!”</p>
                        <div className="row person">
                            <div className="col-3 col-md-1">
                            <img alt="Country Flag" className="avatar" src={`https://www.sciencekids.co.nz/images/pictures/flags96/Canada.jpg`} />
                            </div>
                            <div className="col-9 col-md-11">
                                <div className="info">
                                    <b>AdxwA</b>
                                    <small><b>30</b></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

